import React from "react"

import { useGame } from "~/components/game/context"
import type { PlayerState } from "../../messages"

export const PlayerList: React.FC<{
  className?: string
  onClick?: (player: PlayerState) => void
}> = ({ className, onClick }) => {
  const { game } = useGame()
  return (
    <ul className="grid grid-cols-4 gap-6 justify-self-center">
      {game.players.map((player) => (
        <li
          key={player.id}
          className={className}
          onClick={(e) => {
            onClick?.(player)
            e.currentTarget.closest("form")?.submit()
          }}
        >
          {player.name}
        </li>
      ))}
    </ul>
  )
}
