import type { PropsWithChildren } from "react"
import React, { createContext, Fragment } from "react"
import type { IconType } from "react-icons"
import { LuMonitorSpeaker, LuPause, LuPlay } from "react-icons/lu"
import { Form, useFetcher } from "@remix-run/react"

import { Menu, Transition } from "@headlessui/react"
import { FormProvider, useField, useForm } from "@rvf/remix"
import type { Device, PlaybackState } from "@spotify/web-api-ts-sdk"
import { $path } from "remix-routes"

import { useGame } from "~/components/game/context"
import { useRootData } from "~/hooks/use-root-data"
import type { loader as spotifyLoader } from "~/routes/spotify"
import type { PointValue } from "~/types"
import type { SpotifySubaction } from "~/utils/spotify"
import { spotifyActionValidator } from "~/utils/spotify"

const SpotifyContext = createContext<Partial<PlaybackState> | null>(null)

export function Spotify({ children }: PropsWithChildren) {
  const { playbackState } = useRootData()

  return (
    <SpotifyContext.Provider value={playbackState ?? {}}>
      {children}
    </SpotifyContext.Provider>
  )
}

function useSpotify() {
  const context = React.useContext(SpotifyContext)
  if (context === null) {
    throw new Error("useSpotify must be used within a SpotifyProvider")
  }
  return context
}

Spotify.User = function UserAvatar() {
  const { user } = useRootData()

  return user ? (
    <img
      src={user.image}
      alt={user.name}
      title={user.name}
      className="w-8 rounded-full"
    />
  ) : null
}

Spotify.DeviceSelector = function DeviceSelector() {
  const fetcher = useFetcher<typeof spotifyLoader>()
  return (
    <Menu>
      <Menu.Button
        onClick={() => {
          fetcher.load($path("/spotify"))
        }}
      >
        <LuMonitorSpeaker size={24} />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute bottom-full right-0 z-10 mb-2 flex w-56 origin-bottom-right flex-col gap-1 rounded-lg bg-inherit p-2 shadow-lg ring-1 ring-black focus:outline-none">
          <Spotify.ActionForm action="selectDevice">
            {fetcher.data?.devices.map((device) => (
              <Spotify.Device key={device.id} {...device} />
            ))}
          </Spotify.ActionForm>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

Spotify.ActionForm = function ActionForm({
  action,
  children,
  className,
}: PropsWithChildren<{
  action: SpotifySubaction
  className?: string
}>) {
  const { device } = useSpotify()

  const form = useForm({
    validator: spotifyActionValidator,
    method: "post",
    action: "/spotify",
  })

  return (
    <FormProvider scope={form.scope()}>
      <Form {...form.getFormProps()} className={className}>
        <input
          type="hidden"
          readOnly
          {...form.getInputProps("payload.currentDeviceId")}
          value={device?.id ?? undefined}
        />
        <input
          type="hidden"
          readOnly
          {...form.getInputProps("subaction")}
          value={action}
        />
        {children}
      </Form>
    </FormProvider>
  )
}

Spotify.Device = function DeviceComponent(device: Device) {
  const deviceId = useField("payload.deviceId")

  return (
    <Menu.Item>
      <span className="flex items-center gap-1">
        {device.is_active && <LuMonitorSpeaker size={24} />}
        <button
          className="text-white"
          type="submit"
          {...deviceId.getInputProps({
            value: device.id!,
          })}
        >
          {device.name}
        </button>
      </span>
    </Menu.Item>
  )
}

Spotify.PlayButton = function PlayButton() {
  const { is_playing } = useSpotify()

  const [action, Icon]: [SpotifySubaction, IconType] = is_playing
    ? ["pause", LuPause]
    : ["play", LuPlay]

  return (
    <Spotify.ActionForm action={action}>
      <button type="submit">
        <Icon size={24} />
      </button>
    </Spotify.ActionForm>
  )
}

Spotify.SongCell = function SongCell({
  category,
  pointValue,
}: {
  category: string
  pointValue: PointValue
}) {
  const { game, isHost, actions } = useGame()

  const answer = game.answers[category]?.[pointValue]
  const { categoryId: currentCategory, pointValue: currentPointValue } =
    game.currentQuestion ?? {}

  const isCurrent =
    currentCategory === category && currentPointValue === pointValue

  const isDisabled = !isHost || !!answer

  const question = game.template.categories.find((it) => it.id === category)
    ?.questions[pointValue]

  return answer ? (
    <>
      <strong dangerouslySetInnerHTML={{ __html: answer.textValue }} />
      <br />
      <small>
        <span
          dangerouslySetInnerHTML={{
            __html: answer.artist,
          }}
        />{" "}
        -{" "}
        <span
          dangerouslySetInnerHTML={{
            __html: answer.song,
          }}
        />
      </small>
    </>
  ) : (
    <Spotify.ActionForm
      action={isCurrent ? "pause" : "play"}
      className="h-full"
    >
      <input
        type="hidden"
        name="payload.spotifyId"
        value={question?.spotifyId}
      />
      <button
        className="size-full rounded-md hover:bg-orange-200 disabled:bg-inherit"
        disabled={isDisabled}
        onClick={() => {
          if (isCurrent) {
            // TODO: Send id of player who got it right
            actions.reveal()
          } else {
            actions.play({
              categoryId: category,
              pointValue,
            })
          }
        }}
      >
        {isCurrent ? "🎵" : pointValue}
      </button>
    </Spotify.ActionForm>
  )
}

Spotify.Controls = function Controls() {
  const { isHost } = useGame()
  return isHost ? (
    <div className="absolute bottom-5 right-5 flex items-center justify-around gap-2 rounded-full bg-spotify-background p-3 text-spotify-text shadow-lg">
      <Spotify.PlayButton />
      <Spotify.DeviceSelector />
      <Spotify.User />
    </div>
  ) : null
}
