import { withZod } from "@rvf/zod"
import type { AccessToken } from "@spotify/web-api-ts-sdk"
import { SpotifyApi } from "@spotify/web-api-ts-sdk"
import type { Session } from "remix-auth-spotify"
import { z } from "zod"

const TRACK_ID_PREFIX = "spotify:track:"

export function createSpotifyApi(clientId: string, session: Session) {
  return SpotifyApi.withAccessToken(
    clientId,
    {
      access_token: session.accessToken,
      token_type: session.tokenType ?? "Bearer",
      expires_in: (session.expiresAt - Date.now()) / 1000,
      refresh_token: session.refreshToken!,
    } satisfies AccessToken,
    {
      deserializer: {
        async deserialize<TReturnType>(response: Response) {
          const text = await response.text()

          if (
            text.length > 0 &&
            response.headers.get("content-type")?.includes("application/json")
          ) {
            const json = JSON.parse(text)
            return json as TReturnType
          }

          return null as TReturnType
        },
      },
    },
  )
}

const spotifyActionSchema = z.union([
  z.object({
    subaction: z.literal("play"),
    payload: z
      .object({
        currentDeviceId: z.string().nullish(),
        spotifyId: z
          .string()
          .optional()
          .transform((it) =>
            typeof it === "string"
              ? [it.startsWith(TRACK_ID_PREFIX) ? it : TRACK_ID_PREFIX + it]
              : it,
          ),
      })
      .optional(),
  }),
  z.object({
    subaction: z.literal("pause"),
    payload: z
      .object({
        currentDeviceId: z.string(),
      })
      .optional(),
  }),
  z.object({
    subaction: z.literal("selectDevice"),
    payload: z.object({
      deviceId: z.string(),
    }),
  }),
])

export const spotifyActionValidator = withZod(spotifyActionSchema)

type SpotifyAction = z.infer<typeof spotifyActionSchema>

export type SpotifySubaction = SpotifyAction["subaction"]
