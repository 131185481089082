import usePartySocket from "partysocket/react"

import { useRootData } from "~/hooks/use-root-data"

type Options = Omit<Parameters<typeof usePartySocket>[0], "host">

export function useSocket(options: Options) {
  const rootData = useRootData()
  return usePartySocket({
    host: rootData.partykitHost,
    ...options,
  })
}
