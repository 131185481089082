import { z } from "zod"

export type GameTemplate = z.infer<typeof gameTemplateSchema>

const questionTemplateSchema = z.object({
  textValue: z.string(),
  song: z.string(),
  artist: z.string(),
  spotifyId: z.string().optional(),
})

export type Question = z.infer<typeof questionTemplateSchema>

export const pointValue = z.enum(["100", "200", "300", "400", "500"])

export type PointValue = z.infer<typeof pointValue>

const categoryTemplateSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  questions: z.record(pointValue, questionTemplateSchema),
})

export const gameTemplateSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  categories: z.array(categoryTemplateSchema),
})

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()])

type Literal = z.infer<typeof literalSchema>

type Json = Literal | { [key: string]: Json } | Json[]

const jsonSchema: z.ZodType<Json> = z.lazy(() =>
  z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]),
)

const stringToJSONSchema = z
  .string()
  .transform((str, ctx): z.infer<typeof jsonSchema> => {
    try {
      return JSON.parse(str)
    } catch (e) {
      ctx.addIssue({ code: "custom", message: "Invalid JSON" })
      return z.NEVER
    }
  })

export const messageSchema = stringToJSONSchema.pipe(
  z
    .object({
      action: z.enum(["start", "reset", "finish"]),
    })
    .or(
      z.object({
        action: z.enum(["play", "reveal"]),
        payload: z.object({
          categoryId: z.string().uuid(),
          pointValue,
          player: z.string().optional(),
        }),
      }),
    ),
)

export type Message = z.infer<typeof messageSchema>
