import type { PropsWithChildren } from "react"
import { createContext, useContext, useMemo, useState } from "react"
import { useNavigate } from "react-router"

import { Board } from "~/components/game/board"
import { Finished } from "~/components/game/finished"
import { Lobby } from "~/components/game/lobby"
import { useSocket } from "~/hooks/use-socket"
import type { Message, PointValue } from "~/types"
import type { GameState, PlayerState } from "../../../messages"

type Actions = {
  start(): void
  finish(): void
  reset(): void
  reveal(player?: PlayerState): void
  play(question: { categoryId: string; pointValue: PointValue }): void
}

const GameContext = createContext<{
  game: GameState
  isHost: boolean
  actions: Actions
} | null>(null)

export function useGame() {
  const game = useContext(GameContext)
  if (!game) {
    throw new Error("useGame must be used within a GameProvider")
  }
  return game
}

type Props = PropsWithChildren<{
  id: string
  game: GameState
  player: PlayerState
  DEBUG?: true
}>

export function Game({
  children,
  id,
  game: initialGame,
  player,
  DEBUG,
}: Props) {
  const navigate = useNavigate()
  const [game, setGame] = useState<GameState>(initialGame)

  const isHost = game.host?.id === player.id

  const socket = useSocket({
    party: "game",
    room: id!,
    id: player.id,
    query: player,
    onMessage(evt) {
      if (!evt.data || evt.data === "undefined") {
        return
      }

      if (evt.data === "finished") {
        navigate("/")
        return
      }

      const data = JSON.parse(evt.data) as GameState
      setGame(data)
    },
  })

  const actions = useMemo<Actions>(() => {
    function sendMessage(message: Message) {
      socket.send(JSON.stringify(message))
    }

    return {
      start: sendMessage.bind(null, {
        action: "start",
      }),
      finish: sendMessage.bind(null, {
        action: "finish",
      }),
      reset: sendMessage.bind(null, {
        action: "reset",
      }),
      play(question) {
        sendMessage({
          action: "play",
          payload: question,
        })
      },
      reveal(player) {
        if (game.currentQuestion) {
          sendMessage({
            action: "reveal",
            payload: {
              ...game.currentQuestion,
              player: player?.id,
            },
          })
        }
      },
    }
  }, [socket, game.currentQuestion])

  return (
    <GameContext.Provider
      value={{
        game,
        isHost,
        actions,
      }}
    >
      {DEBUG && (
        <pre>
          <code>{JSON.stringify(game, null, 2)}</code>
        </pre>
      )}
      {children}
    </GameContext.Provider>
  )
}

Game.Lobby = Lobby
Game.Board = Board
Game.Finished = Finished
