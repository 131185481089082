import { useGame } from "~/components/game/context"
import { PlayerList } from "~/components/player-list"
import { Spotify } from "~/spotify/context"
import { pointValue } from "~/types"

export function Board() {
  const { game, isHost, actions } = useGame()
  if (game.state !== "ongoing") {
    return null
  }

  const { categories } = game.template

  return (
    <>
      <table className="w-full border-separate border-spacing-1">
        <thead>
          <tr>
            {categories.map((it) => (
              <th key={it.id} className="py-3 text-sm md:text-2xl">
                {it.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {pointValue.options.map((pointValue) => (
            <tr key={pointValue}>
              {categories.map((category) => (
                <td
                  key={category.id}
                  className="h-10 rounded-md border border-gray-200 bg-orange-100 text-center text-xs shadow-sm"
                >
                  <Spotify.SongCell
                    category={category.id}
                    pointValue={pointValue}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {isHost && (
        <>
          <div className="flex flex-col gap-5">
            <button
              className="m-4 rounded border border-red-300 p-2"
              onClick={actions.reset}
            >
              Reset
            </button>
            <Spotify.ActionForm action="pause">
              <PlayerList onClick={actions.reveal} />
            </Spotify.ActionForm>
          </div>
        </>
      )}
    </>
  )
}
