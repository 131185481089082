import { useGame } from "~/components/game/context"

export function Finished() {
  const { game, isHost, actions } = useGame()

  if (game.state !== "finished") {
    return null
  }

  const results = Object.values(game.answers)
    .flatMap((it) => Object.entries(it))
    .reduce<Record<string, number>>((acc, [pointValue, answer]) => {
      if (answer.player) {
        acc[answer.player] ??= 0
        acc[answer.player] += Number(pointValue)
      }
      return acc
    }, {})

  const ranking = game.players
    .map((it) => ({
      name: it.name,
      score: results[it.id] ?? 0,
    }))
    .sort((a, b) => b.score - a.score)

  return (
    <div className="flex flex-col items-center gap-5">
      <h1 className="text-5xl font-bold text-red-500">Game Over</h1>
      <ol className="flex flex-col gap-3 text-xl">
        {ranking.map(({ name, score }, i) => (
          <li key={name} className="flex gap-2">
            <span>
              {i === 0 ? "🥇" : i === 1 ? "🥈" : i === 2 ? "🥉" : null}
            </span>
            <span>{name}:</span>
            <strong className="text-blue-900">{score}</strong>{" "}
          </li>
        ))}
      </ol>

      {isHost && (
        <button
          className="rounded-md bg-green-500 p-2 shadow-md hover:cursor-pointer hover:bg-green-600 hover:shadow-lg"
          onClick={actions.finish}
        >
          Finish game
        </button>
      )}
    </div>
  )
}
