import { useGame } from "~/components/game/context"
import { PlayerList } from "~/components/player-list"

export function Lobby() {
  const { game, isHost, actions } = useGame()
  if (game.state !== "lobby") {
    return null
  }

  return (
    <div className="flex flex-col items-center gap-10 text-center">
      <h1 className="text-6xl font-bold text-orange-300 shadow-gray-500 text-shadow">
        {game.id}
      </h1>
      <PlayerList className="text-lg" />
      {isHost ? (
        <button
          // disabled={game.players.length < 2}
          className="w-fit rounded-lg bg-green-500 px-5 py-2 text-white shadow-md hover:cursor-pointer hover:bg-green-600 hover:shadow-lg disabled:cursor-not-allowed disabled:opacity-40"
          onClick={actions.start}
        >
          Start
        </button>
      ) : null}
    </div>
  )
}
